import React from 'react'
import { graphql, Link, navigate } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Layout from '../../components/layout'
const axios = require('axios')
import header from '../../images/CleanAir-Desktop-Header-1920x340-Curve-DataHub-2x.png'
import headerMobile from '../../images/CleanAir-Mobile-Header-1080x550-Curve-DataHub-2x.png'
import mi from '../../images/icon.png'
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polygon,
  OverlayView,
} from '@react-google-maps/api'
import paths from '../../data/gmpolygon.json'

import { Container, Row, Col } from 'reactstrap'
import LinkBack from '../../components/link-back'

class MonitoringStationsIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sites: null,
      sitesIndex: null,
      indexed: false,
    }
  }

  componentDidMount() {
    this.setState({ indexed: false })
    axios('https://cleanairgm.com/.netlify/functions/getSites').then(
      response => {
        this.setState({ sites: response.data })
      }
    )

    axios('https://cleanairgm.com/.netlify/functions/getSitesIndex').then(
      response => {
        this.setState({ sitesIndex: response.data })
      }
    )
  }

  // https://www.airqualityengland.co.uk/TfGM/site-index

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const linkBoxes = get(
      this,
      'props.data.contentfulMonitoringStations.linkBoxes'
    )

    if (
      this.state.sites &&
      this.state.sitesIndex &&
      !this.state.indexed &&
      (typeof this.state.sites === 'object' ||
        typeof this.state.sites === 'array')
    ) {
      let sites = this.state.sites

      //filter out unwanted sites
      sites = sites.filter(s => s.SiteCode !== 'TAME')
      sites = sites.filter(s => s.SiteCode !== 'MAN8')
      this.state.sitesIndex.forEach(si => {
        let key = Object.keys(si)[0]

        let site = sites.find(s => s.SiteCode === si[key].SiteCode)
        if (site) {
          site.Value = si[key].Value
        }
      })

      this.setState({ sites: sites, indexed: true })
    }
    const options = {
      fillColor: 'black',
      fillOpacity: 0.1,
      strokeColor: 'red',
      strokeOpacity: 1,
      strokeWeight: 2,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1,
    }

    const centerOverlayView = (width, height) => ({
      x: -(width / 2),
      y: -(height / 2),
    })

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={'Latest Air Quality | ' + siteTitle} />
          <div className="page-banner page-banner--disclaimer">
            <img className="d-none d-lg-block" src={header} alt="" />
            <img className="d-block d-lg-none" src={headerMobile} alt="" />
            <h1>Latest Air Quality</h1>
          </div>
        </div>

        <Container>
          <LinkBack to={'/data-hub'} title={'back to Data hub'} />

          <p>
            The automatic monitoring stations provide hourly data on nitrogen
            dioxide (NO<sub>2</sub>) and particulate matter (PM<sub>10</sub> and
            where available smaller PM<sub>2.5</sub> particles) levels in the
            air. This short-term data is collected for the purpose of the Local
            Air Quality Management Area, not the Greater Manchester Clean Air
            Plan. By clicking on the monitoring stations below you can see
            provisional results from the previous 24 hours.​{' '}
          </p>

          {/* <p>
            The automatic monitoring stations provide hourly data on the NO
            <sub>2</sub> and PM<sub>10</sub> levels. By clicking on the
            monitoring stations below you can see the previous 24 hours and
            download historical data.
          </p> */}
          <div
            aria-hidden="true"
            tabindex="-1"
            aria-label="Map showing monitoring station data in Greater Manchester."
            className="mt-5 mb-5"
          >
            <LoadScript
              id="script-loader"
              googleMapsApiKey="AIzaSyA1yf9mWk13ntuoG5Lia0P-i3UH_igYi-A"
            >
              <GoogleMap
                className="map"
                id="example-map"
                mapContainerStyle={{
                  height: '500px',
                  width: '100%',
                }}
                zoom={10}
                center={{
                  lat: 53.5151979,
                  lng: -2.35003194,
                }}
              >
                <Polygon paths={paths} options={options} />

                {this.state.indexed &&
                  this.state.sites.map((s, i) => {
                    return (
                      <OverlayView
                        key={i}
                        position={{ lat: s.Latitude, lng: s.Longitude }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={centerOverlayView}
                      >
                        <button
                          onClick={() => {
                            navigate(
                              `/data-hub/monitoring-stations/${s.SiteCode}`
                            )
                          }}
                          className={`air-index c${s.Value || '0'}`}
                        >
                          {s.Value || '-'}
                        </button>
                      </OverlayView>
                    )
                  })}
              </GoogleMap>
            </LoadScript>
          </div>

          <div className="quality-index">
            <h2>Daily air quality index</h2>
            <Row>
              <Col sm={12} lg={3}>
                <h3>Low</h3>
                <p>
                  <span className={`air-index c1 mr-4`}>1</span>
                  <span className={`air-index c2 mr-4`}>2</span>
                  <span className={`air-index c3`}>3</span>
                </p>
              </Col>
              <Col sm={12} lg={3}>
                <h3>Moderate</h3>
                <p>
                  <span className={`air-index c4 mr-4`}>4</span>
                  <span className={`air-index c5 mr-4`}>5</span>
                  <span className={`air-index c6`}>6</span>
                </p>
              </Col>
              <Col sm={12} lg={3}>
                <h3>High</h3>
                <p>
                  <span className={`air-index c7 mr-4`}>7</span>
                  <span className={`air-index c8 mr-4`}>8</span>
                  <span className={`air-index c9`}>9</span>
                </p>
              </Col>
              <Col sm={12} lg={3}>
                <h3>Very High</h3>
                <p>
                  <span className={`air-index c10 mr-4`}>10</span>
                </p>
              </Col>
            </Row>
          </div>

          <p className="mt-4 pt-4">
            This displays the{' '}
            <a href="/daily-air-quality-index">
              Daily Air Quality Index (DAQI)
            </a>{' '}
            which is numbered 1-10 and divided into four bands, low (1) to very
            high (10), to indicate immediate short-term air pollution levels in
            a simple way, similar to the sun index or pollen index.​{' '}
          </p>

          <p>
            While these are not linked to legal limits it can help to show
            whether you are likely to be at risk from air pollution on that
            particular day. It also recommends what you can do and gives health
            advice to consider over the next few hours or days.{' '}
          </p>

          <p>
            The <a href="/daily-air-quality-index">DAQI</a> doesn’t take into
            account the cumulative effect of long-term exposure to air pollution
            over months and years which may contribute to chronic health
            conditions – similar to smoking.{' '}
          </p>

          <p>
            Air pollution is above legal annual average limits in some areas of
            our region. As a result, government has instructed Greater
            Manchester to produce and implement a Clean Air Plan to address the
            harmful effects of long-term exposure to NO<sub>2</sub> pollution.{' '}
          </p>

          <p>
            There is separate long-term monitoring of annual average levels of
            NO<sub>2</sub> air pollution for the purposes of the Greater
            Manchester Clean Air Plan.
          </p>

          <p>
            Information on the data measured at these Automatic Monitoring
            Stations for comparison with the legal air quality standards and
            objectives is included in the{' '}
            <a href="/data-hub/monitoring-reports">
              Greater Manchester Air Quality Annual Status Report
            </a>
            .
          </p>

          {this.state.indexed && (
            <div className="mt-5 mb-5">
              <Row>
                {this.state.sites.map((s, i) => {
                  return (
                    <Col sm={12} lg={3}>
                      <Link
                        className="air-quality-button"
                        to={`/data-hub/monitoring-stations/${s.SiteCode}`}
                        key={`si${i}`}
                      >
                        <h2>{s.SiteName}</h2>
                        <h3>
                          Air quality index{' '}
                          <span className={`air-index c${s.Value} ml-3`}>
                            {s.Value || '-'}
                          </span>
                        </h3>
                      </Link>
                    </Col>
                  )
                })}
              </Row>
            </div>
          )}
          {!this.state.indexed && (
            <div className="mt-5 mb-5">
              <Row>
                <Col>Monitoring station data is currently unavailable.</Col>
              </Row>
            </div>
          )}
        </Container>
      </Layout>
    )
  }
}

export default MonitoringStationsIndex

export const pageQuery = graphql`
  query MonitoringStationsQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
